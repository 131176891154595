<template>
  <div class="flex flex-col space-y-2">
    <span>
      {{ label }}
    </span>
    <div
      v-tooltip="{
        content: value,
        onShow: () => textOverflows
      }"
    >
      <div ref="textContainer">
        <info-span-copy
          :text="value"
          :disabled="!clickToCopy"
          :data-cy="id"
          class="flex justify-start text-gray-700 w-full"
        />

        <span
          ref="text"
          class="relative -z-5 whitespace-nowrap text-transparent"
        >
          {{ value || "-" }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import InfoSpanCopy from "@/views/deals/components/InfoSpanCopy.vue";

defineProps({
  /**
   * Allow user to click to copy field value
   */
  clickToCopy: {
    type: Boolean,
    default: false
  },
  /**
   * Identifier for copy function
   */
  id: {
    type: String,
    default: ""
  },
  /**
   * Required label for field
   */
  label: {
    type: String,
    default: ""
  },
  /**
   * Required value for field
   */
  value: {
    type: [String, Number],
    default: ""
  }
});

const textContainer = ref<HTMLElement | null>(null);
const text = ref<HTMLElement | null>(null);

const textOverflows = computed(
  () =>
    !!textContainer.value &&
    !!text.value &&
    text.value.offsetWidth > textContainer.value.clientWidth
);
</script>
